$c-white: #ffffff;
$c-gray: #e9e8eb;
$c-dark-gray: #c4c4c4;
$c-dark: #2c2c2c;
$c-black: #000000;
$c-green: #11c759;
$c-ocean-green: #3fe2c5;
$c-yellow: #f8c032;
$c-violet: #b264d2;
$c-pink: #ef69b1;
$c-light-yellow: #ffc876;
$c-ocean-blue: #5cdefb;

$layout-breakpoints: (
    extralargedesktop: 1920px,
    largedesktop: 1280px,
    desktop: 1020px,
    tablet: 768px,
    phone: 576px
) !default;

$layout-font-size: (
    extralargedesktop: 16px,
    largedesktop: 14px,
    desktop: 12px,
    tablet: 10px,
    phone: 8px
);
