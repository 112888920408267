@import "~styles/shared";

.lobbyPage {
  position: relative;
  padding: 2.5em 3.75em;
  width: 100%;
  height: 100vh;
  background: $c-violet;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.smallLogo {
  position: absolute;
  top: 2.5em;
  left: 3.75em;
}

.githubLink {
  color: $c-white;
  position: absolute;
  bottom: 2.5em;
  left: 3.75em;
  width: 1.5em;

  &:hover {
    opacity: 0.7;
  }
}
