@import "~styles/shared";

.SetupNickname {
  &__input {
    width: 80%;
    margin: 2.25em 0;
  }

  &__form {
    width: 100%;
    max-width: 37.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    font-size: 3.125em;
    color: $c-white;
    margin: 0;
  }
}
