@import "~styles/shared";

.Logo {
  color: $c-pink;

  &--large {
    width: 37.5em;
  }

  &--medium {
    width: 20em;
  }

  &--small {
    width: 8.4375em;
  }

  &--tiny {
    width: 6.25em;
  }
}
