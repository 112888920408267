@import "~styles/shared";

.container {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparentize($c-black, 0.4);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $c-gray;
  padding: 2em;
  width: 90vw;
  height: 90vh;
  border-radius: 10px;
  box-shadow: 0 0 1.25em 0px darken($c-gray, 20%);
  color: $c-white;
}

.modalScroll {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.close {
  position: absolute;
  top: 1.25em;
  right: 1.25em;
}

.ruleImg {
  width: 100%;
}
