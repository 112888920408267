@import "~styles/shared";

.Lobby {
  &__title {
    font-size: 2.625em;
    color: $c-white;
    font-weight: 500;
    margin: 0;
  }

  &__subtitle {
    font-size: 1.25em;
    color: $c-white;
    margin: .75em 0 0;
  }

  &__link {
    display: flex;
    margin: 2.25em 0;
  }

  &__link-box {
    border: .1875em solid darken($c-violet, 10%);
    background: darken($c-violet, 5%);
    font-size: 1.125em;
    color: $c-white;
    padding: .75em 2em;
    font-weight: 400;
    text-align: center;
    border-radius: .625em;
  }

  &__link-button {
    margin-left: 1.25em;
  }

  &__options {
    display: flex;
  }

  &__players {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $c-white;
    font-weight: 500;
    font-size: 1.125em;
  }

  &__player {
    margin: .75em 0;
    font-weight: 500;

    &::after {
      display: block;
      content: "";
      width: 100%;
      height: .3125em;
      margin-top: .375em;
      border-radius: .625em;
    }

    &--active::after {
      background-color: $c-green;
    }

    &--inactive::after {
      background-color: $c-yellow;
    }
  }

  &__status-message {
    margin-top: 1.25em;
    color: $c-white;
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
  }
}
