@import "~styles/shared";

.CreateGame {
  &__title {
    font-size: 3.125em;
    font-weight: 700;
    color: $c-white;
    margin: 0;
  }

  &__subtitle {
    font-size: 2em;
    color: $c-white;
    font-weight: 500;
    margin: 1.25em 0 1.875em;
  }

  &__options {
    display: flex;
  }
}

.CreateGameButton {
  width: 7.5em;
  height: 7.5em;
  margin: 1.5em;

  &__count {
    font-size: 2em;
  }
}
