@import "~styles/shared";

.ButtonChangeNickname {
  position: fixed;
  bottom: 2.5em;
  right: 3.75em;
  color: $c-white;
  text-decoration: none;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}
