@import "styles/shared";

.text {
  margin: 3.75em 0 2.5em;
  max-width: 37.5em;
  color: $c-white;
  text-align: center;
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1.5;
}

.logo {
  max-width: 100%;
}
