@import "~styles/shared";

.sidebar {
  position: absolute;
  left: 0;
  background: $c-violet;
  z-index: 1;
  width: auto;
  height: 100%;
  padding: 1.5em 3.125em 1.5em 1em;
  display: flex;
  align-items: center;
  overflow: auto;
  flex-direction: column;
  box-shadow: .25em 0px 0px rgba(0, 0, 0, 0.14);
  transform: translate(calc(-100% + 3.125em), 0);
  transition: transform 0.2s ease;
  border-radius: 0 1.25em 1.25em 0;

  &:hover {
    transform: translate(0, 0);
  }

  &::after {
    content: attr(data-show-text);
    width: 4.375em;
    border-top: .125em solid $c-white;
    display: block;
    position: absolute;
    top: 50%;
    right: -1.5em;
    font-size: .75em;
    letter-spacing: 1px;
    padding-top: .25em;
    font-weight: 700;
    text-align: center;
    transform: rotate(-90deg) translate(0, -50%);
    color: $c-white;
  }

  &Logo {
    margin-bottom: 1.25em;
    flex-shrink: 0;
  }
}

.sidebarPinned {
  transform: translate(0, 0);
  border-radius: 0;

  &::after {
    display: none;
  }
}

.lockToggle {
  cursor: pointer;
  position: absolute;
  right: 2.5em;
  padding: .625em;
  top: .625em;

  &:hover {
    opacity: 0.7;
  }
}

.sidebarLock {
  width: 1.875em;
  color: $c-white;
}

.sidebarBoard:global(.PlayerBoard) {
  font-size: .625em;
  margin: 1.875em .25em;
  margin-left: 3.125em;
  flex-shrink: 0;

  :global(.MinusPoints__tile) {
    background: lighten($c-violet, 8%);
  }
}

.playerName {
  font-weight: 700;
  color: $c-white;
  font-size: 1em;
  top: -.375em;
  transform: translate(0, -100%);
  position: absolute;
}

.userScore {
  margin: .625em 0 1em;
  font-size: 1.5em;
  color: $c-white;
  font-weight: 700;

  .scoreValue {
    color: $c-ocean-green;
  }
}

.playerScore {
  font-weight: 700;
  color: $c-ocean-green;
  right: 0;
  font-size: 1em;
  top: -.375em;
  transform: translate(0, -100%);
  position: absolute;
}

.activeBoard {
  box-shadow: 0 0 0 .1875em lighten($c-green, 5%);

  .playerName {
    color: lighten($c-green, 10%);
  }
}
