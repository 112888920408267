@import "~styles/shared";

.Lang {
  position: absolute;
  top: 2.5em;
  right: 3.75em;
  display: flex;

  &__button {
    cursor: pointer;
    border: none;
    background: none;
    display: block;
    font-size: .875em;
    color: $c-white;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    &--active {
      opacity: 1;
      text-decoration: underline;
    }
  }
}
