@import "~styles/shared";

@mixin buttonTheme($color) {
  background: $color;
  @include shadow($color: $color);

  &:hover:not([disabled]) {
    @include shadow($inset: true, $color: $color);
  }
}

.button {
  border-radius: 0.6em;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: none;
  padding: 0.75em 1.5em;
  text-align: center;
  font-size: 1.25em;
  font-weight: 600;
  color: $c-white;
  cursor: pointer;

  &--pink {
    @include buttonTheme($c-pink);
  }

  &--yellow {
    @include buttonTheme($c-light-yellow);
  }

  &--blue {
    @include buttonTheme($c-ocean-blue);
  }

  &--green {
    @include buttonTheme($c-green);
  }

  &--size-small {
    padding: .75em 1.5em;
    font-size: 1em;
  }

  &:not([disabled]):hover {
    transform: translate(.125em, .125em);
  }

  &[disabled] {
    opacity: 0.3;
    cursor: initial;
  }
}
