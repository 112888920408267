@mixin shadow($inset: false, $color: $c-violet) {
  $dark: darken($color, 20%);
  $light: lighten($color, 10%);
  $size: 3px;

  @if $inset {
    box-shadow: inset $size $size 0px $dark;
  } @else {
    box-shadow: $size $size 0px $dark, -#{$size} -#{$size} 0px $light;
  }
}

@mixin inverse-shadow($inset: false, $inverse: false, $color: $c-violet) {
  $dark: darken($color, 20%);
  $light: lighten($color, 10%);
  $size: 3px;

  @if $inset {
    box-shadow: none, inset $size $size 0px $dark;
  } @else {
    box-shadow: $size $size 0px $light, -#{$size} -#{$size} 0px $dark;
  }
}
