@import "~styles/shared";

.ButtonBack {
  position: absolute;
  top: 5.625em;
  left: 3.75em;
  color: $c-white;
  text-decoration: none;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}
