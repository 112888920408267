@import "~styles/shared";

.Tile {
  width: em(36);
  height: em(36);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: em(5);
  flex-shrink: 0;

  &--empty-slot {
    background: #f0f2f8;
    box-shadow: inset em(2) em(2) 0px rgba(0, 0, 0, 0.15),
      inset em(-2) em(-2) 0px rgba(255, 255, 255, 0.35);
  }

  &--movable {
    cursor: pointer;

    &:hover {
      transform: scale(1.08);
    }
  }

  &--slot {
    box-shadow: inset em(3) em(3) 0px rgba(0, 0, 0, 0.1);
    transform: scale(0.8);

    > * {
      opacity: 0.2;
    }
  }

  &--full {
    box-shadow: em(2) em(2) 0px rgba(0, 0, 0, 0.15);
  }

  &--selected {
    box-shadow: 0 0 0 em(3) rgb(10, 73, 133);
  }

  > svg {
    width: 100%;
  }
}
