@import "~styles/shared";

.input {
  border-radius: .625em;
  border: none;
  padding: .75em 2.625em;
  text-align: center;
  font-size: 1.25em;
  font-weight: 500;
  color: $c-white;
  outline: none;
  background: darken($c-violet, 5%);
  @include inverse-shadow($color: $c-violet);

  &[disabled] {
    background: $c-violet;
  }

  &::placeholder {
    color: lighten($c-violet, 5%);
    font-size: 1.125em;
    font-weight: 500;
  }
}
